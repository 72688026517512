import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';

const NoteScanner = ({ data }) => {
  return (
    <Layout>
      <SEO title="JetScan® 150 note scanner" />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="flex flex-wrap w-full pb-8 mx-auto mb-8 border-b-2 border-transparent max-w-7xl">
        <div className="flex items-center justify-center w-full md:w-1/2">
          <div className="w-full max-w-lg px-4 mx-auto">
            <h2 className="text-sm font-bold leading-none tracking-wide uppercase text-brand-blue">
              JetScan® 150 note scanner
            </h2>
            <h3 className="mb-6 text-3xl font-bold leading-none">
              Save time, increase security and improve productivity
            </h3>
            <p className="mb-4">
              The JetScan 150 1.5 pocket banknote scanning and counting machine
              offers you greater productivity because they offer non-stop
              processing. JetScan 150 processes next generation banknotes with
              ease, allowing for maximum note processing productivity.
            </p>
            <p className="mb-4">
              The JetScan 150 note sorter quickly and accurately identifies the
              denomination of each and every note – at speeds up to 940 mixed
              notes per minute. Questionable, unreadable, unfaced/unoriented
              cash is off-sorted to the reject pocket without stopping the
              machine. This means greater productivity and time savings.
            </p>
            <p className="mb-4">
              JetScan currency processing machines are high-functioning cash
              counters that sort mixed motes, face and orient notes and catch
              counterfeits. While counting one denomination, the scanner will
              off-sort any rogue notes without missing a beat. A $20 is never
              counted as a $5, which eliminates errors, speeds processing and
              frees operators to focus on other activities.
            </p>
            <p className="mb-4">
              It also has advanced counterfeit detection capabilities. Outfitted
              with magnetic, fluorescent ultraviolet, infrared sensors, you'll
              be sure to catch most counterfeit bills, stopping them at the
              point of receipt or catching them before circulating them back to
              the market.
            </p>
            <ul className="mb-4 ml-4 list-disc">
              <li>
                <strong>Feeder type:</strong> Automatic feeder with auto-sensing
                document detection
              </li>
              <li>
                <strong>Transport:</strong> Beltless transport path
              </li>
              <li>
                <strong>Four levels of memory:</strong> Keeps separate totals
                for: batch totals, sub-totals, grand totals and day totals
              </li>
              <li>
                <strong>Adjustable pocket and strap stops:</strong> Strap stop
                limits can be established for each denomination and a separate
                pocket limit for mix mode
              </li>
              <li>
                <strong>Unit/value display:</strong> Totals are shown as units
                and dollars
              </li>
              <li>
                <strong>Add function:</strong> For cumulative counting when
                desired
              </li>
              <li>
                <strong>Verification:</strong> Allows currency to be counted or
                recounted without changing day totals
              </li>
              <li>
                <strong>Manual add:</strong> Lets users manually add counts for
                notes that are mutilated and not fully processed
              </li>
              <li>
                <strong>Interface/communications:</strong> USB, RS232 and
                Ethernet
              </li>
              <li>
                <strong>Display:</strong> 10.92 cm colour touch screen
              </li>
            </ul>
            <Accordion allowZeroExpanded>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>Available options</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="p-4">
                    <h3>
                      <strong>Options:</strong>
                    </h3>
                    <p>
                      Optional thermal or impact printers are available for
                      printing reports.
                    </p>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>Specs</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <table>
                    <tbody>
                      <tr>
                        <th align="left" colSpan={2}>
                          {' '}
                          Speed
                        </th>
                      </tr>
                      <tr>
                        <td>Maximum Speed</td>
                        <td>940 notes per minute</td>
                      </tr>
                      <tr>
                        <th align="left" colSpan={2}>
                          {' '}
                          Accuracy
                        </th>
                      </tr>
                      <tr>
                        <td>Accuracy</td>
                        <td>99%+</td>
                      </tr>
                      <tr>
                        <th align="left" colSpan={2}>
                          {' '}
                          Hopper Capacity
                        </th>
                      </tr>
                      <tr>
                        <td>Top Hopper Capacity</td>
                        <td>600 notes</td>
                      </tr>
                      <tr>
                        <td>Lower Hopper Capacity</td>
                        <td>
                          Main stacker pocket: up to 250 notes
                          <br />
                          <br />
                          Reject pocket: 100 notes
                        </td>
                      </tr>
                      <tr>
                        <th align="left" colSpan={2}>
                          {' '}
                          Physical Specs
                        </th>
                      </tr>
                      <tr>
                        <td>Size</td>
                        <td>29.97 cm W x 28.96 cm D x 29.97 cm H</td>
                      </tr>
                      <tr>
                        <td>Weight</td>
                        <td>13.2 kg.</td>
                      </tr>
                      <tr>
                        <th align="left" colSpan={2}>
                          {' '}
                          Documents
                        </th>
                      </tr>
                      <tr>
                        <td>Size</td>
                        <td>
                          Height: 6.12 cm to 10.16 cm.
                          <br />
                          Length: 11.43 cm to 22.23 cm.
                        </td>
                      </tr>
                      <tr>
                        <th align="left" colSpan={2}>
                          {' '}
                          Power Specs
                        </th>
                      </tr>
                      <tr>
                        <td>Voltage</td>
                        <td>100~240VAC, 60/50Hz</td>
                      </tr>
                      <tr>
                        <td>Power Consumption</td>
                        <td>Approximately 165W</td>
                      </tr>
                    </tbody>
                  </table>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
        <div className="w-full px-4 mt-8 md:pl-8 md:w-1/3 md:mt-0">
          <Image fluid={data.NoteScanner.childImageSharp.fluid} />
        </div>
      </div>
    </Layout>
  );
};

NoteScanner.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query NoteScannerQuery {
    NoteScanner: file(relativePath: { eq: "note-scanner.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1764) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default NoteScanner;
